// Generated by Framer (0b5b7f5)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, SVG, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Sjlzk3ncU"];

const serializationHash = "framer-0Be8j"

const variantClassNames = {Sjlzk3ncU: "framer-v-ad73qf"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, tap, width, ...props}) => { return {...props, RB1NN81aC: tap ?? props.RB1NN81aC} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, RB1NN81aC, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Sjlzk3ncU", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapiyayox = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (RB1NN81aC) {const res = await RB1NN81aC(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-ad73qf", className, classNames)} data-framer-name={"Play"} data-highlight layoutDependency={layoutDependency} layoutId={"Sjlzk3ncU"} onTap={onTapiyayox} ref={ref ?? ref1} style={{backdropFilter: "blur(5px)", backgroundColor: "rgba(0, 0, 0, 0.2)", borderBottomLeftRadius: 70, borderBottomRightRadius: 70, borderTopLeftRadius: 70, borderTopRightRadius: 70, WebkitBackdropFilter: "blur(5px)", ...style}}><SVG className={"framer-8vj8my"} data-framer-name={"Play"} fill={"rgb(255, 255, 255)"} intrinsicHeight={24} intrinsicWidth={24} layoutDependency={layoutDependency} layoutId={"WJZYY8Qzw"} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" fill=\"currentColor\" class=\"w-6 h-6\"><path fill-rule=\"evenodd\" d=\"M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z\" clip-rule=\"evenodd\"/></svg>"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-0Be8j.framer-qo5gcx, .framer-0Be8j .framer-qo5gcx { display: block; }", ".framer-0Be8j.framer-ad73qf { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: 64px; justify-content: center; overflow: visible; padding: 10px 7px 10px 10px; position: relative; width: 64px; }", ".framer-0Be8j .framer-8vj8my { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 40px); position: relative; width: 40px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-0Be8j.framer-ad73qf { gap: 0px; } .framer-0Be8j.framer-ad73qf > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-0Be8j.framer-ad73qf > :first-child { margin-left: 0px; } .framer-0Be8j.framer-ad73qf > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 64
 * @framerIntrinsicWidth 64
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"RB1NN81aC":"tap"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerHEJBWysgW: React.ComponentType<Props> = withCSS(Component, css, "framer-0Be8j") as typeof Component;
export default FramerHEJBWysgW;

FramerHEJBWysgW.displayName = "VideoControls";

FramerHEJBWysgW.defaultProps = {height: 64, width: 64};

addPropertyControls(FramerHEJBWysgW, {RB1NN81aC: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerHEJBWysgW, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})